/* DO NOT EDIT */
.report-builder body { font-size: 0px; line-height: 0px; }
.report-builder td { border-collapse: collapse; }
.report-builder p { margin: 10px 0; }
.report-builder ul { margin: 10px 0; padding-left: 20px; }
.report-builder ol { margin: 10px 0; padding-left: 20px; }
.report-builder p:first-child { margin-top: 0; }
.report-builder ul:first-child { margin-top: 0; }
.report-builder ol:first-child { margin-top: 0; }
.report-builder p:last-child { margin-bottom: 0; }
.report-builder ul:last-child { margin-bottom: 0; }
.report-builder ol:last-child { margin-bottom: 0; }
.report-builder p a { color: #2762d9 !important; text-decoration: underline !important; }
.report-builder li a { color: #2762d9 !important; text-decoration: underline !important; }
.report-builder p u { color: #67b617; text-decoration: none; font-weight: bold; font-style: normal; }
.report-builder li u { color: #67b617; text-decoration: none; font-weight: bold; font-style: normal; }
.report-builder li { margin-bottom: 2px; }
.report-builder li:last-child { margin-bottom: 0; }
.report-builder .toc-link { color: #2762d9 !important; text-decoration: underline !important; }
.report-builder .report-link { color: #000000; text-decoration: none; }
.report-builder .browser-link { color: var(--client); text-decoration: underline; }
.report-builder .report-quote-link { color: var(--client); text-decoration: none; }
.report-builder .report-button-link { color: #ffffff; text-decoration: none; }
.report-builder .report-footer-link { color: #ffffff; text-decoration: underline; }
.report-builder .report-quote-title div { border-left: 2px solid var(--client); padding: 5px 0 5px 20px; }
.report-builder .report-quote-text div { border-left: 2px solid var(--client); padding: 5px 0 5px 20px; }
.report-builder .report-quote-meta div { border-left: 2px solid var(--client); padding: 5px 0 5px 20px; }
.report-builder .report-float-img-left { width: 100%; height: auto; padding: 0 0 15px 0; }
.report-builder .report-float-img-right { width: 100%; height: auto; padding: 0 0 15px 0; }
.report-builder .report-result-title-author { line-height: 0px; vertical-align: middle; }
.report-builder .report-result-title-author-img { border-radius: 50%; }
.report-builder .report-result-title-text { font-size: 18px; line-height: 22px; font-weight: bold; }
.report-builder .report-result-title-small-text { font-size: 16px; line-height: 20px; font-weight: bold; }