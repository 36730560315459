$active: #fdf2e9;
.bg-active {
  background: $active;
}

.btn {
  white-space: nowrap;
}

// overridden with client styles in App
// https://ui.docs.amplify.aws/react/theming/css-variables
:root {
  --client: #172544;
}

// no scrolling when authenticating
.authentication-bg {
  overflow: hidden;
}

// the whole app is wrapped in this so only apply these styles when we're actually authenticating
.authentication-bg [data-amplify-authenticator] {
  // white bar down left side
  position: fixed;
  z-index: 1;
  height: 100vh;
  width: 450px;
  background: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

  [data-amplify-container] {
    width: 450px;
  }

  [data-amplify-router] {
    box-shadow: none;
    border: 0;
  }

  [data-amplify-form] {
    padding: 0 40px;

    .amplify-label {
      font-size: 0.875rem;
      color: var(--client);
    }

    .amplify-input {
      padding: 0.75rem 1rem;
      &:focus {
        border-color: var(--client);
        box-shadow: none;
      }
    }

    .amplify-button,
    .amplify-input {
      border-radius: 0;
      font-size: 0.875rem;
    }

    .amplify-button--primary,
    .amplify-button--primary:hover,
    .amplify-button--primary:focus,
    .amplify-button--primary:active {
      background: var(--client);
      margin-top: 1rem;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 0.75px;
      padding: 0.75rem 1rem;
    }

    // hide show password doggle
    .amplify-field__show-password {
      display: none;
    }

    // form errors and field validation errors
    .amplify-alert,
    .amplify-text--error {
      color: #cc0000;
      font-size: 0.875rem;
      background: none;
      padding: 0 0 0 8px;
      border-left: 2px solid;
      .amplify-alert__icon,
      .amplify-alert__dismiss {
        display: none;
      }
    }
  }

  [data-amplify-footer] {
    padding: 1rem 40px 0 40px;
  }

  // can be inside or outside footer with different styles
  .amplify-button--link,
  .amplify-button--link:hover,
  .amplify-button--link:focus,
  .amplify-button--link:active {
    color: var(--client);
    background: #fff;
    display: block;
    width: 100%;
    text-align: center;
  }
}

.outcider-auth-title {
  font-size: 1.15rem;
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-weight: 700;
  color: var(--client);
  &--padded {
    margin: 0 40px 1.5rem 40px;
  }
}

.outcider-auth-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 450px;
  padding: 30px 40px 0 40px;
  background: white;
}

.outcider-auth-footer {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 450px;
  padding: 0 40px 20px 40px;
  background: white;
}

// non-project page has no left menu
body[data-leftbar-compact-mode="condensed"] .wrapper .layout-non-project .content-page {
  margin-left: 0;
}
body[data-leftbar-compact-mode="condensed"] .wrapper .layout-non-project .navbar-custom,
body[data-leftbar-compact-mode="condensed"] .wrapper .layout-non-project .footer {
  left: 0;
}

// side nav with client colours and home link
.side-nav-item.side-nav-home {
  .side-nav-link {
    width: 70px !important;
    background: var(--client-home) !important;
    color: rgba(255, 255, 255, 0.7) !important;
    padding: 23px 0 !important;
  }
}
body[data-leftbar-theme="dark"] .left-side-menu {
  background: var(--client);
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-link {
  color: rgba(255, 255, 255, 0.7);
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: var(--client-hover);
}
body[data-leftbar-theme="dark"] .side-nav .mm-active .side-nav-link {
  background: var(--client-active);
}
.side-nav-item.side-nav-intercom {
  position: absolute !important;
  bottom: 0;
}

// duplicates and results preview modal
.modal-xl {
  max-width: 90vw;
}

.modal-confirmable .modal-body :last-child {
  margin-bottom: 0;
}

.form-error {
  color: $danger;
  input,
  textarea,
  .react-select > div {
    border-color: $danger !important;
  }
}

.form-switch-oneline {
  label {
    margin-bottom: 0;
  }
  .custom-switch {
    display: inline-block;
    margin-left: 0.5rem;
  }
}

// allows "\n" in option labels (used in BlockPanel)
.form-select__option,
.form-select_single-value {
  white-space: pre-wrap !important;
}

.font-normal {
  font-weight: normal;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: bold;
}
.color-orange {
  color: #f2994a;
}
.color-purple {
  color: #994af2;
}

.scroll {
  height: 100%;
  overflow-y: auto !important;
}

.sentry-error-embed-wrapper {
  z-index: 1001 !important;
}

.dropdown-toggle::after {
  margin-left: 0.3em;
}

// TODO probably set up a grey colour used for active states eg agent selected
.card-header,
.card-footer {
  background: #f9f9f9;
}

.click {
  cursor: pointer;
}
.textlink {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

// hide blank div after react-select
.react-select:not(.react-select--is-disabled) > div:last-child {
  display: none;
}

.react-select .react-select--no-options__indicators {
  display: none;
}

.react-toast-notifications__container {
  margin-top: 78px;
  margin-right: 14px;
  .alert {
    margin-bottom: 12px;
    .spinner-border {
      height: 1rem;
      width: 1rem;
      vertical-align: middle;
      margin-right: 0.3rem;
    }
  }
}

// middle of nearest relative
.loading-relative {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
}

// middle of screen taking header into account
.loading-full {
  position: fixed;
  top: calc(50% - 16px + 35px);
  left: calc(50% - 16px);
}

// prompt cards (e.g. agent listing, preview)
.card-prompt {
  background: rgba(255, 255, 255, 0.3);
  color: $gray-500;
}

// results error
.results-error {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.7;
}

// fullscreen card (e.g. export form)
.card-fullscreen {
  background: rgba(255, 255, 255, 0.3);
}

.bg-prompt {
  background: rgba(255, 255, 255, 0.3);
}

// top bar
.navbar-custom .topbar-right-menu {
  margin: 0 0 0 24px;
  display: flex;
}
.navbar-custom .topbar-right-menu .intercom-toggle {
  flex-shrink: 1;
}
.navbar-custom .topbar-right-menu .nav-link {
  margin: 0;
}
.nav-intercom-toggle {
  min-height: $topbar-height;
  min-width: 0 !important;
  padding: 0 15px !important;
}
.topbar-logo {
  margin-right: 24px;
  display: flex;
  align-items: center;
}
.topbar-title {
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  margin: 0;
  line-height: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// card top/side images
.card-img-top {
  background: #ccc;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card-img-side {
  background: #ccc;
  width: 60%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

.results-date-dropdown {
  width: 360px;
  padding: 1rem;
}

.results-save-dropdown .dropdown-toggle::after {
  margin-left: 0;
}

.results-listing {
  margin-bottom: 0;
  border-bottom: 1px solid $card-border-color;
  z-index: 2;
  .card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .results-listing-items {
    flex-grow: 1;
  }
  .results-listing-item {
    padding: 1rem 1.5rem 1rem 3rem;
    border-bottom: $card-border-width solid $card-border-color;
    position: relative;
    &--active {
      background: $active;
    }
    &--checked {
      background: $gray-100;
    }
    &--deleted {
      opacity: 0.5;
      .result-content-title {
        text-decoration: line-through;
      }
    }
  }
}

.results-filters {
  // background: #f9f9f9;
  background: $active;
  padding: 1.2rem 1.5rem;
  border-bottom: 2px solid rgba($gray-600, 0.2);
}

// .results-listing--main {
//   min-height: 100%;
// }

// .results-listing--duplicates {
//   border-bottom: none;
//   border-radius: 0;
// }

.results-preview {
  padding: 0;
  .results-preview-item {
    padding: 1rem 1.5rem;
    border-bottom: $card-border-width solid $card-border-color;
  }
}

.side-panel {
  margin-left: -24px;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 1;
  box-shadow: none;
  background: none;
  .nav {
    background: $active;
    padding-left: 24px;
    padding-right: 12px;
  }
  .nav-tabs.nav-bordered .nav-item .nav-link {
    padding-top: 1.1rem;
    padding-bottom: 1rem;
  }
  .nav-tabs.nav-bordered .nav-item .nav-link.active {
    background: none;
    color: #000;
    border-bottom-width: 3px;
  }
  .nav-tabs.nav-bordered .nav-item.nav-item-icon .nav-link {
    font-size: 30px;
    line-height: 56px;
    padding: 0 0.5rem !important;
  }
  & > .card-body {
    box-shadow: $card-box-shadow;
    border-top-left-radius: 0;
  }
  &--gray {
    .card-body {
      background: #e5e9eb;
    }
  }
  &--white {
    .card-body {
      background: #fff;
    }
  }
}

// .results-listing-panel--main {
//   height: calc(100vh - 70px - 75px);
// }

// .sticky .results-listing-panel--main {
//   height: calc(100vh - 70px);
// }

// .stakeholders-listing-panel--main {
//   height: calc(100vh - 70px - 75px - 20px);
// }

// .results-listing--duplicates .card-body,
// .results-listing-panel--duplicates .card-body {
//   height: 70vh;
// }

// .results-panel {
//   margin-bottom: 0;
//   .card-body {
//     background: #e5e9eb;
//   }
// }

// .result-panel {
//   margin-bottom: 0;
//   .nav {
//     background: $active;
//   }
//   .card-body {
//     background: #fff;
//   }
//   // .nav-item-icon {
//   //   line-height: 0;
//   //   font-size: 30px;
//   //   .nav-link {
//   //     padding-top: 1rem;
//   //   }
//   // }
// }

.result-story {
  padding: 0 0 0 1.5rem;
}
.result-story--preview {
  padding: 0 1rem;
}

.result-author .dropdown {
  display: inline-block;
  margin-left: 2px;
}

.result-language {
  .dropdown-toggle {
    display: inline-block;
  }
}

.result-image {
  float: right;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.spinner-border-xs {
  height: 12px;
  width: 12px;
  border-width: 0.15em;
  vertical-align: middle;
}

.spinner-border-xs--translate {
  margin-top: -1px;
}

.spinner-border-xs--resultjob {
  margin-top: -4px;
}

.result-content {
  // highlights - replicate in: FormEditor, styles/report.mjs, _head.mjml
  u {
    border-bottom: 2px solid #67b617;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
  }
  // rtl
  &--rtl {
    .result-title,
    .result-text {
      direction: rtl;
    }
  }
}

.result-content-title {
  margin-bottom: 0.75rem;
}

.result-content-title-social {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  margin-bottom: 0.5rem;
  height: 1.75rem;
}

.result-content-title-social-with-image {
  padding-left: 2.15rem;
}

.result-content-title-author {
  border-radius: 50%;
  height: 1.75rem;
  width: 1.75rem;
  position: absolute;
  left: 0;
}

// manual excerpt, mimic mb-2 on paragraphs
.result-excerpt {
  p,
  ul,
  ol {
    margin-bottom: 0.75rem;
  }

  // links - replicate in: `.form-editor__editor` style, styles/report.mjs, _head.mjml
  a {
    color: #2762d9;
    text-decoration: underline;
  }
}

.result-embed {
  margin-top: 1.5rem;
}

.result-embed-twitter {
  & > div {
    & > .twitter-tweet {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.result-embed-facebook {
  text-align: center;
}

.result-linked-item {
  display: inline-block;
  width: 16px;
  font-size: 0.8em;
  &--filter {
    vertical-align: text-bottom;
  }
  &--agent {
    vertical-align: middle;
  }
}

.entity {
  display: inline;
  padding: 0 0.3em;
  border-radius: 0.3em;
  background: none;
  color: inherit;
  &.author {
    border: 2px solid rgba(23, 182, 99, 0.2);
  }
  &.person {
    border: 2px solid rgba(182, 23, 36, 0.2);
  }
  &.person.stakeholder {
    background: rgba(182, 23, 36, 0.1);
  }
  &.organisation {
    border: 2px solid rgba(23, 116, 182, 0.2);
  }
  &.organisation.stakeholder {
    background: rgba(23, 116, 182, 0.1);
  }
  &.person i,
  &.organisation i {
    margin-left: 2px;
  }
}

.entity--clickable {
  cursor: pointer;
  &:hover {
    background: $gray-100;
  }
}

.result-controls {
  position: absolute;
  left: 1rem;
  margin-top: 1px;
  line-height: 1.2;
  text-align: center;
  width: 26px;

  &--result {
    float: left;
    position: static;
    margin-left: -2rem;
    margin-top: -1px;
  }

  .custom-checkbox {
    margin-left: 5px;
    margin-bottom: 1px;
  }
  i {
    display: block;
    color: $gray-600;
  }
  .result-controls-top {
    font-size: 24px;
  }
  .result-controls-public-feed {
    font-size: 19px;
    margin-bottom: 2px;
  }
  .result-controls-delete {
    font-size: 20px;
    margin-left: -1px;
  }
}

.dropdown-menu {
  max-height: 50vh;
  overflow-y: scroll;
}

.result-analysis {
  .card:last-child {
    margin-bottom: 0;
  }
  .card-body {
    padding: 12px 24px;
  }
  .row {
    margin-left: -24px;
    margin-right: -24px;
  }
  .row:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid $gray-200;
  }
  .result-analysis-title {
    padding-left: 24px;
    font-weight: 600;
  }
  .result-analysis-content {
    padding-right: 24px;
    p {
      margin: 0;
    }
  }
}

.invalid-feedback {
  font-size: 13px;
  line-height: 1.4;
  margin-top: 0.3rem;
  a {
    color: inherit;
    text-decoration: underline;
  }
}
.invalid-feedback-error-with-helptext {
  margin-top: 0;
}

// nouislider
.noUi-target {
  margin-top: 3px;
}
.noUi-pips-horizontal {
  padding-bottom: 0;
  height: 32px;
}
.noUi-marker {
  background: #ccc;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 8px;
}
.noUi-pips {
  color: $gray-700;
  font-size: 12px;
}
.noUi-FormError {
  margin-top: 40px;
}

.popover-helptext {
  .popover {
    max-width: none;
  }
  .popover-inner {
    padding: 1rem;
    width: 400px;
    font-size: 13px;
    line-height: 1.4;
    color: $text-muted;
  }
  p {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  strong {
    display: block;
  }
}

// react-rte
.form-editor {
  .DraftEditor-editorContainer {
    min-height: 150px;
    max-height: 200px;
    overflow: auto;
  }

  &__editor {
    line-height: 1.4 !important;
    font-size: 14px !important;
    font-family: "Nunito", sans-serif !important;
    color: $body-color !important;

    a {
      color: #2762d9;
      text-decoration: underline;
    }
  }

  &__toolbar > div:last-child {
    float: right;
  }

  &__highlight {
    display: inline-block;
    width: 22px;
    height: 22px;
    position: relative;
    i {
      width: 22px;
      text-align: center;
      font-size: 15px;
      position: absolute;
      top: 0;
      left: 1px;
    }
  }
}

// TODO should this be filter specific (the agent/project status badges have got bigger too)
.badge {
  font-size: 1em;
  font-weight: normal;
  padding: 0.4em 0.8em;
  &--disabled {
    opacity: 0.5;
  }
}

.badge--resultjob {
  font-size: 12px;
}

.vh-duplicates-modal,
.vh-result-preview-modal {
  height: 80vh;
}

.results-menu {
  .list-group {
    border-top: 2px solid rgba($gray-600, 0.2);
    padding: 0.5rem 0;
  }
  .list-group-item {
    padding: 0.3rem 0;
    background: none;
  }
}

// .bootstrap-table {
//   table-layout: fixed !important;
//   thead th {
//     vertical-align: middle;
//     position: relative;
//   }
//   .filter-label {
//     margin-bottom: 0;
//     margin-top: -6px;
//     right: 0.95rem;
//     position: absolute;
//     width: 30%;
//     // &[for="text-filter-column-name"] {
//     //   width: 200px;
//     // }
//   }
//   .react-bs-table-no-data {
//     color: $gray-500;
//     border: none;
//     padding: 0;
//     height: calc(100vh - 220px);
//     position: relative;
//     background: #fff;
//     p {
//       position: absolute;
//       width: 100%;
//       top: 50%;
//       margin-top: -30px;
//     }
//   }
// }

// .bootstrap-table {
//   thead th {
//     padding-top: 0.5rem;
//   }
// }

.bootstrap-table {
  thead th {
    vertical-align: middle;
  }
}

.react-bs-table-no-data {
  // this should fit to whatever height the table is
  display: contents;
  .table-empty {
    color: $gray-500;
    padding: 0;
    p {
      position: absolute;
      width: 100%;
      top: 50%;
    }
  }
  .table-empty-admin {
    // table has no height
    color: $gray-500;
    padding: 26px 0;
    p {
      position: absolute;
      width: 100%;
      bottom: 16px;
    }
  }
  .results-error {
    display: block;
    position: absolute;
    width: 100%;
    top: calc(50% - 30px);
  }
}

.react-bootstrap-table table {
  table-layout: auto;
}

.bootstrap-table-stretch {
  width: 100%;
}

.bootstrap-table-header {
  display: flex;
  align-items: center;
  &--filter {
    flex-grow: 1;
    label {
      min-width: 100px;
      width: 100%;
      // -100 is hack to stop inputs changing th height
      margin: -100rem 0;
    }
  }
}

.bootstrap-table-header-multiline {
  display: flex;
  flex-direction: column;
  &--filter {
    flex-grow: 1;
    label {
      width: 100%;
      margin: 0;
    }
  }
}

.bootstrap-table-row-active {
  background: $active !important;
}

.side-panel-title {
  padding-top: 1.1rem;
}

.side-panel--buttons {
  padding: 11px 0;
}

// results side panel
.side-panel--nav-button-md {
  padding: 1px 0.5rem;
  font-size: 22px;
}
.side-panel--close-button-md {
  margin-left: 0.75rem; // ml-2
  padding: 1px 0.5rem;
  font-size: 22px;
  opacity: 0.5;
}

// all other side panels
.side-panel--close-button-sm {
  margin-left: 0.75rem; // ml-2
  padding: 0 0.5rem;
  font-size: 20px;
  opacity: 0.5;
}

// card table e.g. Alerts, Reports
.card-table {
  // match height of tables
  .card-header {
    padding-top: 0.95rem;
    padding-bottom: 0.95rem;
  }
  thead tr {
    background: #f9f9f9;
  }
}

// chart/table widgets
.card-widget {
  margin-bottom: 0;

  .card-header {
    padding-left: 0.95rem;
    padding-right: 0.95rem;
    font-weight: bold;
  }

  .card-body {
    padding: 0;
    position: relative;
    background: #fff;
    overflow: hidden;
  }

  .react-bootstrap-table {
    overflow: hidden;
  }

  // for overriding `.results-panel .card-body`
  &--empty {
    background: #fff !important;
  }

  .empty {
    color: $gray-500;
    position: absolute;
    background: #fff;
    width: 100%;
    top: 50%;
    margin-top: -30px;
    left: 0;
  }

  &--loading-graybg {
    .card-body {
      background: #e5e9eb !important;
    }
    .spinner-border {
      border-color: $gray-500;
      border-right-color: transparent;
    }
  }
}

.title-divider {
  border-top: 4px solid $gray-400;
}

// result card on dashboard
.card-result {
  .result-content {
    margin-bottom: 0.5rem;
    p:last-child {
      margin-bottom: 0;
    }
  }
  // cutoff excerpt in TopResult
  .card-body {
    display: flex;
    flex-direction: column;
    .result-content {
      flex-shrink: 1;
      overflow: hidden;
    }
  }
}

// dashboard masonry
.masonry {
  display: flex;
  margin-left: -1.25rem;
  width: auto;
}
.masonry-column {
  padding-left: 1.25rem;
  background-clip: padding-box;
}

.form-toggle {
  border-color: $input-border-color;
  font-size: 0.8rem;
  i {
    font-size: 24px;
  }
  &--notselected {
    background-color: #fff !important;
  }
  &--selected {
    color: #fff !important;
    i {
      color: #fff !important;
    }
  }
}

.highcharts-no-data {
  opacity: 0.5;
}

.dropdown-header {
  text-transform: uppercase;
  font-weight: normal;
  color: $gray-500;
  margin-top: 0;
  font-size: 0.75rem;
}

hr.form {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

// must be relative to avoid bug where switch inside a scrolling panel will cause the whole page to jump when used
.custom-switch {
  position: relative;
  z-index: 0; // allows form selects to appear over
}

.custom-switch-inline-label {
  font-weight: normal;
}

// no animation on FormSwitch due to the way defaultValues are instantiated after initial render
.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
  transition-duration: 0s !important;
}

// tables
.table-hover tbody tr:hover,
tbody tr.hover:hover {
  background-color: $gray-100;
}

.table-hover tbody tr.empty:hover {
  background-color: #fff;
}

tr.divider {
  background: $gray-200;
}

// tick/cross icons
.check-yes {
  color: #219653;
}
.check-no {
  color: #eb5757;
}

// table cell with buttons that wrap at smaller breakpoints, buttons have mt-1 so offset top padding
.table-cell-wrap-buttons {
  padding-top: calc(0.95rem - 6px) !important;
}

// truncate react select placeholder
.react-select__placeholder,
.react-select--no-options__placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding-right: 20px;
}

.legend {
  margin-top: 0.5rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid $gray-400;
  font-weight: bold;
  color: $gray-700;
}

// don't wrap lines
.edit-modal-textarea textarea {
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}

@keyframes dev-production-warning {
  0% {
    background-color: #f00;
  }
  50% {
    background-color: #000;
  }
  100% {
    background-color: #f00;
  }
}

.dev-production-warning {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 0;
  color: #fff;
  padding: 1em;
  animation: dev-production-warning 2s infinite;
}
