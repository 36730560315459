@import "../../templates/report/styles/stylesGlobal.css";
@import "../../templates/report/styles/stylesGlobalNonMobile.css";
// app is non-mobile for now so don't include mobile styles
// @import "../../templates/report/styles/stylesGlobalMobile.css";
@import "../../templates/report/styles/stylesInline.css";
@import "../../templates/report/styles/stylesMjAttributes.css";

.report-builder {
  background: #fff;

  &--updating {
    opacity: 0.5;
  }

  // builder ui
  &--block {
    position: relative;
  }

  &--relative {
    position: relative;
  }

  // disable tooltips, mouseovers etc on charts
  &--chart {
    pointer-events: none;
  }

  &--chart-empty {
    color: $gray-500;
    padding: 15px;
  }

  &--ordering {
    position: absolute;
    top: 0;
    width: 94px;
    text-align: right;
    // 94px width + 20px margin
    left: -114px;
  }

  &--actions {
    position: absolute;
    top: 0;
    width: 94px;
    text-align: left;
    // 600px + 20px margin
    left: 620px;
  }

  &--ordering,
  &--actions {
    font-family: $font-family-base;
    .report-builder--action {
      opacity: 0.3;
      box-shadow: none !important;
    }
    .report-builder--action.disabled {
      opacity: 0.15;
    }
    .btn {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  &--editable &--hover:hover {
    .report-builder--ordering .report-builder--action,
    .report-builder--actions .report-builder--action {
      opacity: 0.8;
    }
    .report-builder--ordering .report-builder--action.disabled,
    .report-builder--actions .report-builder--action.disabled {
      opacity: 0.4;
    }
  }

  // adjustment of block/results controls positioning
  &--ordering-block,
  &--actions-block {
    top: 15px;
  }

  // adjustment of result controls positioning
  &--block-type-banner {
    .report-builder--ordering-result,
    .report-builder--actions-result {
      top: 20px;
    }
  }
  &--block-type-headline,
  &--block-type-list,
  &--block-type-table,
  &--block-type-deleted {
    .report-builder--ordering-result,
    .report-builder--actions-result {
      top: 12px;
    }
  }
  &--block-type-focus {
    .report-builder--ordering-result,
    .report-builder--actions-result {
      top: 8px;
    }
  }

  // grid controls sit under result instead of in margins
  &--ordering-actions-grid {
    // mj-wrapper to target results controls not block
    padding-top: 5px;
    padding-bottom: 2px;

    &.report-grid-left {
      padding-left: 25px;
    }
    &.report-grid-right {
      padding-right: 25px;
    }
    .report-builder--ordering,
    .report-builder--actions {
      padding: 0;
      position: static;
    }
    .report-builder--ordering {
      float: left;
      text-align: left;
    }
    .report-builder--actions {
      float: right;
    }
  }

  &--create-block {
    text-align: center;
    background: $active;
    border-top: 2px solid var(--client);
    padding: 15px;
  }

  &--move-type {
    opacity: 0.8;
    font-size: 0.9em;
  }

  // email content
  .report-body {
    width: 600px;
    margin: 0 auto;
    word-wrap: break-word;

    // override padding on email
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  // handle box sizing like email
  mj-wrapper *,
  mj-wrapper *::before,
  mj-wrapper *::after {
    box-sizing: content-box;
  }

  // everything needs to be block
  mj-wrapper,
  mj-section,
  mj-column,
  mj-text,
  mj-image,
  mj-button,
  mj-raw {
    display: block;
  }

  .mj-table {
    display: block;
  }

  // mjml defaults
  // pick relevant styles from e.g. https://github.com/mjmlio/mjml/blob/master/packages/mjml-section/src/index.js
  // can exclude anything set by mj-all in styles/report.mjs
  mj-wrapper,
  mj-section {
    padding: 20px 0;
    text-align: center;
  }
  mj-column {
    width: 100%;
  }
  mj-text {
    padding: 10px 25px;
    text-align: left;
  }
  mj-image {
    padding: 10px 25px;
    text-align: left;
  }
  mj-button {
    text-align: center;
    border-radius: 3px;
    text-decoration: none;
    padding: 10px 25px;
  }

  .mj-table {
    // use margin to inset from edge, not padding, so overrides in mj-class still work
    margin: 0 25px;
    text-align: left;
  }

  // mjml overrides due to slightly different HTML structure...

  // title tables in grid block
  .mj-table[mj-class~="report-grid-left-title-table"] {
    margin: 10px 12.5px 0 25px;
  }
  .mj-table[mj-class~="report-grid-right-title-table"] {
    margin: 10px 25px 0 12.5px;
  }

  // floating images within result-content need clearing
  [mj-class~="result-content"] {
    overflow: hidden;
  }

  // only mj-button used is report-button-link which is replaced with <a>
  .report-button-link,
  .report-button-link:-webkit-any-link {
    margin: 10px 25px;
    display: inline-block;
    color: #fff !important;
    // text-decoration: none;
    // below are mjml defaults
    text-align: center;
    border-radius: 3px;
    text-decoration: none;
    padding: 10px 25px;
  }

  // mj-section with more than one column (side by side)
  .report-section-with-columns {
    display: flex;
    mj-column {
      flex-shrink: 0;
    }
  }

  .report-float-img-left,
  .report-float-img-right {
    margin-top: 10px !important;
  }

  img.report-image-fullwidth {
    width: 100%;
  }
  img.report-grid-left {
    padding-right: 0 !important;
    margin: 10px 12.5px 0 25px;
    width: 100%;
    border-right: 35px solid transparent;
    box-sizing: border-box;
  }
  img.report-grid-right {
    padding-left: 0 !important;
    margin: 10px 25px 0 12.5px;
    width: 100%;
    border-right: 35px solid transparent;
    box-sizing: border-box;
  }

  .report-quote-title,
  .report-quote-text,
  .report-quote-meta {
    border-left: 2px solid var(--client);
    padding: 5px 0 5px 20px;
    margin: 0 25px;
  }

  .report-table-empty {
    color: $gray-500;
  }
}
