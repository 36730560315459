//
// topbar.scss
//

.navbar-custom {
  padding: 0 $grid-gutter-width;
  background-color: $bg-topbar;
  box-shadow: $shadow;
  min-height: $topbar-height;
  position: fixed;
  left: $leftbar-width;
  top: 0;
  right: 0;
  z-index: 1001;
  display: flex;

  .topbar-left {
    background-color: $bg-topbar;
    height: $topbar-height;
    position: fixed;
    z-index: 1;
    width: $leftbar-width;
    text-align: center;
    top: 0;
    left: 0;
    .logo {
      line-height: $topbar-height;
      i {
        display: none;
      }
    }
  }
  .topbar-right-menu {
    position: relative;
    z-index: 1;

    li {
      float: left;
      max-height: $topbar-height;

      &.show {
        .nav-link {
          color: $gray-700;
        }
      }
    }
    .nav-link {
      padding: 0;
      color: $gray-600;
      min-width: 32px;
      display: block;
      text-align: center;
      margin: 0 12px;
      position: relative;
    }
  }

  .app-search {
    form {
      padding: calc(#{$topbar-height - 38px} / 2) 5px calc(#{$topbar-height - 38px} / 2) 0;
      // overflow: hidden;
      max-width: 320px;
    }
  }
}

.topbar-dropdown {
  .nav-link {
    line-height: $topbar-height;
  }
}

/* Search */

.app-search {
  .form-control {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    background-color: $bg-topbar-search;
    box-shadow: none;
  }
  span.search-icon {
    position: absolute;
    z-index: 9;
    font-size: 20px;
    line-height: 38px;
    left: 10px;
    top: 0;
  }
  .input-group-append {
    margin-left: 0;
    z-index: 4;
  }
}

/* Notification */
.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 24px;
    vertical-align: middle;
    line-height: $topbar-height;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 7px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: $danger;
  }

  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      b {
        font-weight: 500;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }
  .topbar-dropdown-menu {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.profile-dropdown {
  min-width: 170px;

  i,
  span {
    vertical-align: middle;
  }
}

.nav-user {
  padding: calc(#{$topbar-height - 39px} / 2) 20px calc(#{$topbar-height - 39px} / 2) 57px !important;
  text-align: left !important;
  position: relative;
  background-color: #fff;
  border: 1px solid $gray-100;
  border-width: 0 1px;
  min-height: $topbar-height;

  .account-user-avatar {
    position: absolute;
    // top: calc(#{$topbar-height - 32px} / 2);
    top: 12px;
    left: 15px;

    img {
      height: 32px;
      width: 32px;
    }
  }
  .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
  }
  .account-user-name {
    display: block;
    font-weight: $font-weight-semibold;
  }
}

.button-menu-mobile {
  border: none;
  color: $dark;
  height: $topbar-height;
  // line-height: $topbar-height;
  line-height: 64px;
  width: 64px;
  background-color: transparent;
  font-size: 28px;
  cursor: pointer;
  float: left;
  flex-shrink: 0;
  // margin-right: 6px;

  border: 1px solid $gray-100;
  border-width: 0 1px;

  i {
    vertical-align: middle;
  }

  &.disable-btn {
    display: none;
  }
}

[data-keep-enlarged="true"] {
  .navbar-custom {
    padding-left: 0;
  }

  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
}

@media (max-width: 600px) {
  .navbar-custom {
    .topbar-right-menu {
      position: initial;
    }

    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .navbar-custom {
    left: 70px;
    right: 0;

    .app-search {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-custom {
    left: 0;
    padding: 0;
  }
  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
  .nav-user {
    padding: 17px 5px 17px 57px !important;

    .account-position,
    .account-user-name {
      display: none;
    }
  }
}

@media (max-width: 360px) {
  .navbar-custom {
    .topbar-right-menu {
      .nav-link {
        margin: 0 6px;
      }
    }

    &.topnav-navbar {
      .container-fluid {
        padding-right: 12px;
        padding-left: 12px;
      }
      .navbar-toggle {
        margin: 27px 3px;
      }
      .button-menu-mobile {
        width: auto;
      }
    }
  }
}

// Scrollable Layout

@include media-breakpoint-up(xl) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
    .navbar-custom {
      position: absolute;
    }
  }
}

// Topbar Dark
.topnav-navbar-dark {
  background-color: $bg-topbar-dark;

  .nav-user {
    background-color: lighten($bg-topbar-dark, 5%);
    border: 1px solid lighten($bg-topbar-dark, 7.5%);
  }

  .topbar-right-menu {
    li {
      &.show {
        .nav-link {
          color: $white;
        }
      }
    }
  }

  .app-search {
    .form-control {
      background-color: $bg-topbar-dark-search;
      color: $white;
    }
    span {
      color: $gray-600;
    }
  }

  .navbar-toggle {
    span {
      background-color: rgba($white, 0.8) !important;
    }
  }
}

body[data-layout-mode="boxed"] {
  .navbar-custom {
    position: relative;
    left: 0 !important;
    margin: -$topbar-height (-$grid-gutter-width / 2) 0;
  }

  &[data-layout="topnav"] {
    .navbar-custom {
      margin: 0;
    }
  }
}
