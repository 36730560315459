/* DO NOT EDIT */
.report-builder mj-wrapper { font-family: Helvetica,Arial,sans-serif; font-size: 15px; line-height: 20px; color: #333333; }
.report-builder [mj-class~="header"] { text-align: center; }
.report-builder [mj-class~="logo"] { padding: 0 0 10px 0; }
.report-builder [mj-class~="title"] { font-size: 28px; line-height: 36px; font-weight: bold; text-align: center; text-transform: uppercase; padding-top: 0; padding-bottom: 5px; }
.report-builder [mj-class~="date"] { font-size: 13px; line-height: 16px; color: #666; font-weight: bold; text-align: center; text-transform: uppercase; padding: 0; }
.report-builder [mj-class~="browser"] { font-size: 13px; line-height: 16px; color: var(--client); font-weight: bold; text-align: center; text-transform: uppercase; text-decoration: underline; padding: 0; }
.report-builder [mj-class~="heading"] { font-size: 24px; line-height: 31px; font-weight: bold; text-align: center; text-transform: uppercase; padding-top: 0; padding-bottom: 0; }
.report-builder [mj-class~="subheading"] { font-size: 18px; line-height: 23px; text-align: center; padding-top: 5px; padding-bottom: 0; }
.report-builder [mj-class~="block-title"] { font-size: 20px; line-height: 26px; font-weight: bold; text-align: center; text-transform: uppercase; padding-top: 0; padding-bottom: 5px; }
.report-builder [mj-class~="block-title-grid"] { font-size: 20px; line-height: 26px; font-weight: bold; text-align: center; text-transform: uppercase; padding-top: 0; padding-bottom: 10px; }
.report-builder [mj-class~="block-text"] { padding-bottom: 0; }
.report-builder [mj-class~="block-text-without-title"] { padding-top: 0; padding-bottom: 0; }
.report-builder [mj-class~="result-title"] { font-size: 18px; line-height: 22px; font-weight: bold; padding-bottom: 5px; }
.report-builder [mj-class~="result-title-small"] { font-size: 16px; line-height: 20px; font-weight: bold; padding-bottom: 5px; }
.report-builder [mj-class~="result-title-quote"] { font-size: 16px; line-height: 20px; font-weight: bold; padding-bottom: 0px; }
.report-builder [mj-class~="result-meta"] { font-size: 13px; line-height: 17px; color: var(--client); text-transform: uppercase; padding-top: 0; padding-bottom: 0; }
.report-builder [mj-class~="result-meta-small"] { font-size: 12px; line-height: 16px; color: var(--client); text-transform: uppercase; padding-top: 0; padding-bottom: 0; }
.report-builder [mj-class~="result-content"] { font-size: 15px; line-height: 20px; padding-top: 5px; color: #4f4f4f; }
.report-builder [mj-class~="result-content-small"] { font-size: 14px; line-height: 19px; color: #4f4f4f; }
.report-builder [mj-class~="result-button"] { font-size: 16px; line-height: 21px; font-weight: bold; background-color: var(--client); color: #ffffff; }
.report-builder [mj-class~="footer"] { font-size: 12px; line-height: 16px; text-align: center; color: #ffffff; padding-top: 0; padding-bottom: 0; }
.report-builder [mj-class~="bg-odd"] { background-color: #ffffff; }
.report-builder [mj-class~="bg-even"] { background-color: #f2f2f2; }
.report-builder [mj-class~="bg-client"] { background-color: var(--client); }
.report-builder [mj-class~="border-top-1px-client"] { border-top: 1px solid var(--client); }
.report-builder [mj-class~="border-top-2px-client"] { border-top: 2px solid var(--client); }
.report-builder [mj-class~="border-top-8px-client"] { border-top: 8px solid var(--client); }
.report-builder [mj-class~="border-bottom-1px-client"] { border-bottom: 1px solid var(--client); }
.report-builder [mj-class~="width-35"] { width: 35%; }
.report-builder [mj-class~="width-50"] { width: 50%; }
.report-builder [mj-class~="width-65"] { width: 65%; }
.report-builder [mj-class~="p-0"] { padding: 0; }
.report-builder [mj-class~="pt-0"] { padding-top: 0px; }
.report-builder [mj-class~="pt-20"] { padding-top: 20px; }
.report-builder [mj-class~="pb-0"] { padding-bottom: 0px; }
.report-builder [mj-class~="pb-10"] { padding-bottom: 10px; }
.report-builder [mj-class~="pb-15"] { padding-bottom: 15px; }
.report-builder [mj-class~="p-0-pt-10-pb-10"] { padding: 10px 0 10px 0; }
.report-builder [mj-class~="p-0-pt-10"] { padding: 10px 0 0 0; }
.report-builder [mj-class~="p-0-pb-10"] { padding: 0 0 10px 0; }
.report-builder [mj-class~="p-0-pb-15"] { padding: 0 0 15px 0; }
.report-builder [mj-class~="p-0-pb-20"] { padding: 0 0 20px 0; }