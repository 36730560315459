/* DO NOT EDIT */
@media only screen and (min-width: 480px) {
.report-builder .report-body { padding-top: 20px !important; padding-bottom: 20px !important; }
.report-builder .full-width-mobile { width: auto !important; }
.report-builder .full-width-mobile img { width: 100% !important; }
.report-builder .report-focus-left { padding-right: 12.5px !important; }
.report-builder .report-focus-right { padding-left: 12.5px !important; }
.report-builder .report-grid-left table { width: 100%; }
.report-builder .report-grid-right table { width: 100%; }
.report-builder .report-grid-left { padding-right: 12.5px !important; }
.report-builder .report-grid-right { padding-left: 12.5px !important; }
.report-builder .report-float-img-left { float: left !important; width: 33% !important; padding: 2px 15px 15px 0 !important; }
.report-builder .report-float-img-right { float: right !important; width: 33% !important; padding: 2px 0 15px 15px !important; }
}