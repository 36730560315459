// // start debug
// // MOBILE
// @include media-breakpoint-up(xs) {
//   body {
//     background-color: red;
//   }
// }
// // MOBILE
// @include media-breakpoint-up(sm) {
//   body {
//     background-color: blue;
//   }
// }
// // TABLET
// @include media-breakpoint-up(md) {
//   body {
//     background-color: green;
//   }
// }
// // DESKTOP
// @include media-breakpoint-up(lg) {
//   body {
//     background-color: yellow;
//   }
// }
// // LARGE DESKTOP
// @include media-breakpoint-up(xl) {
//   body {
//     background-color: pink;
//   }
// }
// // end debug

// make authenticator full width on xs/sm
@include media-breakpoint-down(sm) {
  .authentication-bg [data-amplify-authenticator] {
    width: 100vw;
    [data-amplify-container] {
      width: 100%;
    }
  }
  .outcider-auth-logo,
  .outcider-auth-footer {
    width: 100vw;
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  .content-page {
    padding-top: 70px;
    padding-bottom: 20px;
  }
}

// fixed layout only on tablet+
@include media-breakpoint-up(md) {
  body.fixed {
    overflow: hidden;
    .content-page {
      padding-bottom: 0;
      min-height: auto;
    }
  }
}

// fixed layout only on desktop+
@include media-breakpoint-up(lg) {
  body.fixed--lg {
    overflow: hidden;
    .content-page {
      padding-bottom: 0;
      min-height: auto;
    }
  }
}

// viewport height helpers
.vh-with-title {
  height: calc(100vh - /* top */ 70px - /* title */ 75px - /* bottom */ 20px);
}
.vh-with-title-wrapped-buttons {
  height: calc(
    100vh - /* top */ 70px - /* title */ 75px - /* bottom */ 20px - /* random amount to make the positioning the same */
      40px
  );
}

// tags
@include media-breakpoint-down(md) {
  .tags-title:not(.tags-title--first) {
    margin-top: 1.5rem !important;
  }
}
@include media-breakpoint-up(lg) {
  .tags-list {
    height: calc(100vh - /* top */ 70px - /* title */ 75px - /* form with title */ 80px - /* bottom */ 20px);
  }
}

// PageTitle right buttons are moved underneath title
.page-title-box .page-title-right--xs-sm-md {
  margin-top: -10px;
}

// position side panels as new pages on xs/sm/md
@include media-breakpoint-down(md) {
  .responsive-panel--container {
    position: relative;
    .responsive-panel--panel {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      overflow: hidden;
      .side-panel {
        margin-left: 0;
        border-radius: 0.25rem !important;
        overflow: hidden;
      }
    }
  }
}

// results/stakeholder menu as popup
@include media-breakpoint-down(md) {
  .results-menu {
    height: auto;
    max-height: calc(100vh - /* top */ 70px - /* title */ 75px);
    background: #fff;
    position: absolute;
    top: -58px;
    padding: 1rem 1rem 0.5rem 1rem;
    z-index: 10;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: $card-border-radius;
    width: 250px;
  }
}

// results/stakeholders settings as popup
.settings-popup-container {
  position: relative;
}
.settings-popup {
  height: auto;
  background: #fff;
  position: absolute;
  top: 48px;
  right: 0;
  padding: 1rem;
  z-index: 10;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: $card-border-radius;
  min-width: 300px;
}

// specific to results settings
.results-settings {
  display: flex;
  align-items: center;
  .results-settings-duplicates {
    display: flex;
    width: 100%;
  }
}
.results-settings--popup {
  flex-direction: column-reverse;
  .results-settings-duplicates {
    margin-top: 12px;
  }
}

// buttons to switch between results/table and results/stakeholder closed panel
.responsive-closed-panel-buttons {
  position: absolute;
  margin-top: -38px;
}

// border to separate from result total header
@include media-breakpoint-down(md) {
  .results-filters {
    border-top: 1px solid rgba($gray-600, 0.2);
  }
}

// card side images
@include media-breakpoint-down(xs) {
  .card-img-side {
    width: 100%;
    height: 150px;
  }
}

// duplicates and results preview modal
@include media-breakpoint-down(sm) {
  .modal-xl {
    max-width: 100%;
    &.modal-dialog {
      margin: 0.5rem;
    }
    &.modal-dialog-centered {
      min-height: calc(100% - 1rem);
      align-items: normal;
    }
  }

  // full height - modal margin - modal header
  .vh-duplicates-modal,
  .vh-result-preview-modal {
    height: calc(100vh - 1rem - 56px);
  }
}

// tighter nav tabs spacing
@include media-breakpoint-down(xs) {
  .nav-tabs.nav-bordered li a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

// smaller results nav
@include media-breakpoint-down(xs) {
  .side-panel--nav-button-md {
    padding-left: 0;
    padding-right: 0;
  }
}

// remove column padding
@include media-breakpoint-down(sm) {
  .result-analysis {
    .result-analysis-title,
    .result-analysis-content {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

// full width result image
@include media-breakpoint-down(xs) {
  .result-image {
    max-width: none !important;
    max-height: none !important;
    width: 100%;
    margin-left: 0;
    margin-bottom: 1rem;
    float: none;
  }
}

// top bar
@include media-breakpoint-down(sm) {
  .navbar-custom .topbar-right-menu .nav-link {
    margin: 0;
  }
}

// form editior toolbar fit to one line
@include media-breakpoint-down(sm) {
  .form-editor__toolbar {
    zoom: 0.75;
  }
}
